import { createBrowserHistory } from "history";
import { checkOneGrant } from "./roles";

export const referralsOrgAllows = ["wave4", "develop"]
const history = createBrowserHistory();

export const getSubdomain = () => {
  const default_org_name = "wave4";

  // Obtén el nombre de host desde la URL actual
  const host = window.location.hostname;
  // Divide el nombre de host en partes usando el punto como separador
  const parts = host.split(".");
  // El primer elemento en parts será el subdominio (si existe)
  if (parts.length > 2) {
    let tempOrg =  parts[0];
    if(tempOrg === "meet"){
      return "wave4";
    }else{
      return parts[0];
    }
  } else {
    return default_org_name;
  }
  // Si no hay subdominio, devuelve null o un valor predeterminado según sea necesario
};

export const getAsURLParam = () => {
  const default_org_name = undefined;
  const org =
    new URLSearchParams(window.location.search).get("org") || "develop";
  let org_temp = localStorage.getItem("org_id");
  localStorage.removeItem("org_id");
  if (org) {
    return org;
  } else {
    if (org_temp) {
      //HERE ADD THE ORG TO PARAMS
      if(org_temp === "meet"){
        return "wave4";
      }else{
        return org_temp;
      }
    } else {
      //HERE PUT THE DEFAULT ORG NAME
      return default_org_name;
    }
  }
};

export function dashboardGrantsValidation({ user, navigate }) {
  if (user) {
    let org = user["https://wherebyfront.vercel.app/orgname"];
    let url = "";
    let tempOrg = getOrgName();
    if(tempOrg === "meet"){
      tempOrg ="wave4";
    }
    if ( tempOrg !== org) {
      url = `https://${org}.mypxch.com`;
    }
    if (!user.email_verified) {
      window.location.replace(`${url}/verify`);
    } else if (!checkOneGrant(user)) {
      //window.location.replace(`${url}/payment`);
      window.location.replace(
        `https://mypxch.com/pricing${getPxchOrgUrl()}`
      );
    } else {
      if (tempOrg !== org) {
        history.push(`${url}/`);
      }
      return false;
    }
    return true;
  }
  return false;
}

export function getOrgName() {
  if (process.env.NODE_ENV === "production") {
    return getSubdomain(); // comentar  para que funcione en local
  } else {
    return getAsURLParam(); // descomentar  para que funcione en local
  }
}

/**
 * Determines the organization name based on the environment.
 * @returns {string | undefined} The organization name.
 */
export function getOrgAllowsRef() {
  let org = getOrgName();
  if(org === "meet"){
    org="wave4";
  }  
  if (referralsOrgAllows.includes(org)) {
    return true;
  } else {
    return false;
  }
}


export function getOrgNameForMeetings() {
  let org = "";
  if (process.env.NODE_ENV === "production") {
    org = getSubdomain(); // comentar  para que funcione en local
  } else {
    org =  getAsURLParam(); // descomentar  para que funcione en local 
  }
  if(org === "wave4"){
    return "meet"
  }else{
    return org;
  }
}



export function getAuth0Org() {
  let org = getOrgName();
  if (org === "commonsense") {
    return { org_name: org, org_code: "org_xziwYxCka5dQigiz" };
  } else {
    return undefined;
  }
}


export function getPxchOrgUrl() {
  let org = getOrgName();
  if (org !== "wave4") {
    return `?org=${org}`;
  } else {
    return "";
  }
}

export function getRecurlyEnvFetch(env) {
  //esta validacion solo es hasta que se lance commonsense
  if (getOrgName() === "commonsense") {
    return "dev";
  } else {
    if (env === "dev" || env === "production") {
      return env || process.env.REACT_APP_RECURLY_ENV || "production";
    } else {
      return process.env.REACT_APP_RECURLY_ENV || "production";
    }
  }
}
